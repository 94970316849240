import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  ListGroup,
  Modal,
  Button,
  Carousel,
  Form,
  ButtonGroup,
} from "react-bootstrap";
import { FaThLarge, FaListUl, FaArrowDown, FaArrowUp } from "react-icons/fa";
import { ArrowRightCircle } from "react-bootstrap-icons";
import { useSpring, animated } from "react-spring";
import { useDrag } from "react-use-gesture";
import "./MonumentList.css";

const DraggableMonumentList = ({
  monuments,
  displayMode,
  onModeChange,
  activeFilter,
  onSelectMonument,
  onSingleOutMonument,
  selectedMonuments,
  onCreateMap,
  isVisible,
  toggleVisibility,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [currentMonument, setCurrentMonument] = useState(null);

  const handleOpenModal = (monument) => {
    setCurrentMonument(monument);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [{ y }, api] = useSpring(() => ({ y: isVisible ? 0 : 300 }));

  const bind = useDrag(
    ({ down, movement: [, my], direction: [, dy], distance, cancel }) => {
      if (down && distance > 300) cancel();
      api.start({
        y: down ? my : dy > 0 ? 300 : 0,
        immediate: down,
      });
      if (!down && dy > 0 && my > 150) {
        toggleVisibility(false);
      }
      if (!down && dy < 0 && my < 150) {
        toggleVisibility(true);
      }
    },
    { from: () => [0, y.get()], bounds: { top: 0, bottom: 300 }, rubberband: true }
  );

  useEffect(() => {
    api.start({ y: isVisible ? 0 : 300 });
  }, [isVisible, api]);

  const handleTouchStart = (event) => {
    event.preventDefault();
  };

  const handleTouchMove = (event) => {
    event.preventDefault();
  };

  const handleArrowDownClick = () => {
    if (y.get() !== 300) {
      api.start({ y: 300 });
    }
  };

  return (
    <>
      <Container
        className="monument-list-container"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        <animated.div {...bind()} style={{ y }} className="draggable-list">
          <div className="drag-handle">
            <span className="hide-list-text" onClick={() => toggleVisibility(false)}>
              esconder lista
            </span>
            <FaArrowDown size={14} onClick={handleArrowDownClick} />
          </div>
          <div className="list-header">
            <h2 className="monument-list-topHeader">
              {activeFilter ? "Lista de Monumentos" : "Pesquisas Populares"}
            </h2>
            <ButtonGroup className="display-toggle">
              <Button
                variant="outline-primary"
                onClick={() => onModeChange("cards")}
                className={displayMode === "cards" ? "active" : ""}
              >
                <FaThLarge size={20} />
              </Button>
              <Button
                variant="outline-primary"
                onClick={() => onModeChange("list")}
                className={displayMode === "list" ? "active" : ""}
              >
                <FaListUl size={20} />
              </Button>
            </ButtonGroup>
          </div>
          {displayMode === "cards" ? (
            <ListGroup className="monument-list-group">
              {monuments.map((monument) => (
                <Card key={monument.id} className="monument-card shadow-sm">
                  <Card.Header className="monument-card-header">
                    {monument.name}
                  </Card.Header>
                  <Card.Body className="monument-card-body">
                    <Card.Title className="monument-card-title">
                      {monument.name}
                    </Card.Title>
                    <Card.Text className="monument-card-text">
                      {monument.smallDescription}
                      <Button
                        variant="link"
                        className="text-primary ml-2"
                        onClick={() => handleOpenModal(monument)}
                      >
                        <ArrowRightCircle size={24} />
                      </Button>
                    </Card.Text>
                    <Form.Check
                      type="checkbox"
                      label="Adicionar ao mapa personalizado"
                      onChange={() => onSelectMonument(monument)}
                      checked={selectedMonuments.includes(monument)}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Destacar"
                      onChange={() => onSingleOutMonument(monument)}
                      checked={
                        selectedMonuments.length === 1 &&
                        selectedMonuments[0] === monument
                      }
                    />
                  </Card.Body>
                </Card>
              ))}
            </ListGroup>
          ) : (
            <ul className="monument-simple-list">
              {monuments.map((monument) => (
                <li key={monument.id} onClick={() => handleOpenModal(monument)}>
                  {monument.name}
                </li>
              ))}
            </ul>
          )}
          {selectedMonuments.length > 0 && (
            <Button
              variant="primary"
              onClick={onCreateMap}
              className="create-map-button"
            >
              Criar mapa
            </Button>
          )}
        </animated.div>
      </Container>
      {currentMonument && (
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          className="monument-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>{currentMonument.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Carousel>
              {currentMonument.images.map((image, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100"
                    src={image}
                    alt={`${currentMonument.name} ${index + 1}`}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
            <Card.Text className="modal-description">
              {currentMonument.description}
            </Card.Text>
            <Card.Text className="modal-text">
              <strong>Distrito:</strong> {currentMonument.district}
            </Card.Text>
            <Card.Text className="modal-text">
              <strong>Estilo:</strong> {currentMonument.architectureStyle}
            </Card.Text>
            <Card.Text className="modal-text">
              <strong>Século:</strong> {currentMonument.century}
            </Card.Text>
            <Card.Text className="modal-text">
              <strong>Abertura:</strong> {currentMonument.open} -{" "}
              {currentMonument.close}
            </Card.Text>
            <Card.Text className="modal-text">
              <strong>Preço do bilhete:</strong> {currentMonument.ticketPrice}
            </Card.Text>
            <Card.Link
              className="modal-link"
              href={currentMonument.linkToGoogleStreet}
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>Google Street Link:</strong> ver
            </Card.Link>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default DraggableMonumentList;
