// src/component/mapPart/MapPart.jsx //

import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import './MapPart.css';
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const Map = ({ monuments }) => {
  const mapContainerRef = useRef(null);

  useEffect(() => {
    if (!monuments.length) return;

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/jpcabral91/clvz9brqg02ck01qz0rg67q4n',
      center: [monuments[0].coordinates[0], monuments[0].coordinates[1]],
      zoom: 12.99
    });

    monuments.forEach(monument => {
      new mapboxgl.Marker()
        .setLngLat(monument.coordinates)
        .setPopup(new mapboxgl.Popup({ offset: 30 }).setHTML(`<h3>${monument.name}</h3><p>${monument.smallDescription}</p>`))
        .addTo(map);
    });

    return () => map.remove();
  }, [monuments]);

  return <div className="map-container" ref={mapContainerRef} />;
};

export default Map;
