import React, { useState, useMemo, useEffect } from "react";
import { Button } from "react-bootstrap";
import Header from "./component/header/Header";
import UnderHeader from "./component/underHeader/UnderHeader";
import MonumentList from "./component/monumentList/MonumentList";
import DraggableMonumentList from "./component/monumentList/DraggableLMonumentList"; // Import the new draggable list
import Map from "./component/mapPart/MapPart";
import { monuments } from "./data/monuments";
import { FaEye, FaArrowUp } from 'react-icons/fa';
import "./App.css";

// Initial filter settings
const initialFilters = {
  district: "",
  architectureStyle: "",
  century: "",
  open: "",
  ticketPrice: "",
  accessibility: "",
  monument: false,
  museum: false,
};

function App() {
  const [filters, setFilters] = useState(initialFilters);
  const [activeFilter, setActiveFilter] = useState(false);
  const [selectedMonuments, setSelectedMonuments] = useState([]);
  const [displayPopular, setDisplayPopular] = useState(true);
  const [displayMode, setDisplayMode] = useState("cards");
  const [isVisible, setIsVisible] = useState(true);
  const [isUnderHeaderVisible, setIsUnderHeaderVisible] = useState(false); // New state for UnderHeader visibility

  const filteredMonuments = useMemo(() => {
    if (!activeFilter || Object.keys(filters).every((key) => !filters[key])) {
      return monuments;
    }
    return monuments.filter((monument) =>
      Object.entries(filters).every(([key, value]) => {
        if (value === "" || value === false) return true;
        if (typeof value === "boolean") {
          return monument[key] === value;
        }
        return String(monument[key])
          .toLowerCase()
          .includes(value.toLowerCase());
      })
    );
  }, [filters, activeFilter]);

  const popularMonuments = useMemo(() => [monuments[0], monuments[3]], []);

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setActiveFilter(Object.values(newFilters).some((value) => value !== ""));
    setDisplayPopular(false);
  };

  const handleResetFilters = () => {
    setFilters(initialFilters);
    setActiveFilter(false);
    setDisplayPopular(false);
  };

  const handleSelectMonument = (monument) => {
    setSelectedMonuments((prevSelected) =>
      prevSelected.includes(monument)
        ? prevSelected.filter((m) => m !== monument)
        : [...prevSelected, monument]
    );
  };

  const handleSingleOutMonument = (monument) => {
    setSelectedMonuments([monument]);
  };

  const handleCreateMap = () => {
    console.log("Creating map with selected monuments:", selectedMonuments);
  };

  const handleMonumentSelectFromSearch = (monument) => {
    handleSingleOutMonument(monument);
  };

  const handleDisplayModeChange = (mode) => {
    setDisplayMode(mode);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleUnderHeader = () => {
    setIsUnderHeaderVisible(!isUnderHeaderVisible);
  };

  const toggleVisibility = (visible) => {
    setIsVisible(visible);
  };

  return (
    <div className="app-container">
      <Header
        onFilterChange={handleFilterChange}
        filters={filters}
        monuments={monuments}
        onMonumentSelect={handleMonumentSelectFromSearch}
        isMobile={isMobile}
        isUnderHeaderVisible={isUnderHeaderVisible}
        toggleUnderHeader={toggleUnderHeader}
      />
      {isMobile && isUnderHeaderVisible && (
        <UnderHeader
          filters={filters}
          onFilterChange={handleFilterChange}
          onReset={handleResetFilters}
          isMobile={isMobile} // Pass isMobile prop
        />
      )}
      {!isMobile && (
        <UnderHeader
          filters={filters}
          onFilterChange={handleFilterChange}
          onReset={handleResetFilters}
          isMobile={isMobile} // Pass isMobile prop
        />
      )}
      {isMobile ? (
        <div className="mobile-layout">
          <div className="map-containerDiv-mobile">
            <Map
              monuments={
                selectedMonuments.length > 0
                  ? selectedMonuments
                  : filteredMonuments
              }
            />
          </div>
          {isVisible && (
            <div className="monument-list-container-mobile">
              <DraggableMonumentList
                monuments={filteredMonuments}
                displayMode={displayMode}
                onModeChange={handleDisplayModeChange}
                activeFilter={activeFilter}
                onSelectMonument={handleSelectMonument}
                onSingleOutMonument={handleSingleOutMonument}
                selectedMonuments={selectedMonuments}
                onCreateMap={handleCreateMap}
                isVisible={isVisible}
                toggleVisibility={toggleVisibility} // Add this prop
              />
            </div>
          )}
          {!isVisible && (
            <Button className="show-list-button" onClick={() => toggleVisibility(true)}>
              <FaArrowUp size={20} />
            </Button>
          )}
        </div>
      ) : (
        <div className="content-row">
          <div className="map-containerDiv">
            <Map
              monuments={
                selectedMonuments.length > 0
                  ? selectedMonuments
                  : filteredMonuments
              }
            />
          </div>
          <div className="monument-list-container">
            <MonumentList
              monuments={filteredMonuments}
              displayMode={displayMode}
              onModeChange={handleDisplayModeChange}
              activeFilter={activeFilter}
              onSelectMonument={handleSelectMonument}
              onSingleOutMonument={handleSingleOutMonument}
              selectedMonuments={selectedMonuments}
              onCreateMap={handleCreateMap}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
