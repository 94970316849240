// /src/component/underHeader/UnderHeader.jsx //

import React from 'react';
import IconFilter from './iconFilter/IconFilter'; 
import FiltersReset from './filtersReset/FiltersReset'; 
import './UnderHeader.css';

// UnderHeader component renders a section below the header with icon filters and a reset button.
const UnderHeader = ({ filters, onFilterChange, onReset, isMobile }) => (
  <div className="underHeaderContainer">
    {/* Container for the icon filters */}
    <div className="iconFilterContainer">
      {/* Render the IconFilter component for selecting monument types */}
      <IconFilter filters={filters} onFilterChange={onFilterChange} />
    </div>
    
    {/* Conditionally render the reset filters button based on the isMobile prop */}
    {!isMobile && (
      <div className="filtersResetContainer">
        {/* Render the FiltersReset component for resetting all filters */}
        <FiltersReset onReset={onReset} />
      </div>
    )}
  </div>
);

export default UnderHeader;
