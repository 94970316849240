// src/component/header/searchBar/SearchBar.jsx //

import React, { useState } from 'react';
import './SearchBar.css';

// SearchBar component allows users to search for monuments by name.
const SearchBar = ({ monuments, onMonumentSelect }) => {
  // searchTerm state holds the current value of the search input.
  const [searchTerm, setSearchTerm] = useState('');
  // searchResults state holds the filtered list of monuments based on the search term.
  const [searchResults, setSearchResults] = useState([]);

  // handleSearchChange function updates the searchTerm state and filters the monuments.
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value.length > 0) {
      const results = monuments.filter(monument =>
        monument.name.toLowerCase().includes(value.toLowerCase())
      ).slice(0, 6); // Limit the results to 6 items.
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  // handleResultClick function handles the event when a search result is clicked.
  const handleResultClick = (monument) => {
    setSearchTerm('');
    setSearchResults([]);
    onMonumentSelect(monument);
  };

  // Return the JSX for rendering the search bar and search results.
  return (
    <div className="search-bar">
      <input
        type="text"
        placeholder="Search for monuments..."
        value={searchTerm}
        onChange={handleSearchChange}
        className="search-input"
      />
      {searchResults.length > 0 && (
        <ul className="search-results">
          {searchResults.map((monument) => (
            <li key={monument.id} onClick={() => handleResultClick(monument)}>
              <img src={monument.images[0]} alt={monument.name} className="search-result-image" />
              <span>{monument.name}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;
