// /src/component/header/Header.jsx

import React from 'react';
import FilterPart from './filterPart/FilterPart';
import SearchBar from './searchBar/SearchBar';
import './Header.css';
import logoHeader from "../../images/logoHeader.PNG";
import { FaFilter } from 'react-icons/fa';

// Header component is responsible for rendering the header section of the application.
const Header = ({ onFilterChange, filters, monuments, onMonumentSelect, isMobile, isUnderHeaderVisible, toggleUnderHeader }) => (
  <div className="headerContainer">
    {/* Render the logo image */}
    <img className="headerIcon" src={logoHeader} alt="logoHeader" />
    
    {/* Container for the filter inputs */}
    <div className="filterContainer">
      {/* Render the FilterPart component for selecting filters */}
      <FilterPart onFilterChange={onFilterChange} filters={filters} monuments={monuments} />
    </div>
    
    {/* Container for the search bar */}
    <div className="searchContainer">
      {/* Render the SearchBar component for searching monuments */}
      <SearchBar monuments={monuments} onMonumentSelect={onMonumentSelect} />
      
      {/* Render the toggle button for UnderHeader on mobile */}
      {isMobile && (
        <button className="toggle-under-header-button" onClick={toggleUnderHeader}>
           <FaFilter size={15} className='filterUnderHeader' />
        </button>
      )}
    </div>
  </div>
);

export default Header;
