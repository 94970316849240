// /src/component/underHeader/iconFilter/IconFilter.jsx //

import React from 'react';
import { FaLandmark, FaUniversity, FaRoad, FaFlag, FaBuilding } from 'react-icons/fa'; // Import additional icons
import './IconFilter.css';

const IconFilter = ({ filters, onFilterChange }) => {
  const handleIconClick = (type) => {
    const newFilters = {
      ...filters,
      [type]: !filters[type],
    };
    onFilterChange(newFilters);
  };

  return (
    <div className="icon-filter-container">
      <div
        className={`icon-filter ${filters.museum ? 'active' : ''}`}
        onClick={() => handleIconClick('museum')}
      >
        <FaUniversity size={25} className="iconMark" />
        <span>Museus</span>
      </div>
      <div
        className={`icon-filter ${filters.monument ? 'active' : ''}`}
        onClick={() => handleIconClick('monument')}
      >
        <FaLandmark size={25} className="iconMark" />
        <span> Monumentos </span>
      </div>
      <div
        className={`icon-filter ${filters.n2 ? 'active' : ''}`}
        onClick={() => handleIconClick('n2')}
      >
        <FaRoad size={25} className="iconMark" />
        <span>N2</span>
      </div>
      <div
        className={`icon-filter ${filters.nationalMonument ? 'active' : ''}`}
        onClick={() => handleIconClick('nationalMonument')}
      >
        <FaFlag size={25} className="iconMark" />
        <span className='textLargeIcon'>Monumento Nacional</span>
      </div>
      <div
        className={`icon-filter ${filters.municipalMonument ? 'active' : ''}`}
        onClick={() => handleIconClick('municipalMonument')}
      >
        <FaBuilding size={25} className="iconMark" />
        <span className='textLargeIcon'>Monumento Municipal</span>
      </div>
    </div>
  );
};

export default IconFilter;
