// /src/component/underHeader/filtersReset/FilterReset.jsx //


import React from 'react';
import { Button } from 'react-bootstrap';
import './FiltersReset.css';

// FiltersReset component provides a button to reset all filters to their initial state.
const FiltersReset = ({ onReset }) => (
  <div className="filters-reset">
    {/* Render the reset button with an onClick handler */}
    <Button variant="outline-secondary" onClick={onReset} className="reset-button">
      Apagar Filtros
    </Button>
  </div>
);

export default FiltersReset;
