export const monuments = [
    {
        id: "padrao-dos-descobrimentos",
        name: "Padrão dos Descobrimentos",
        description: "A monument celebrating the Portuguese Age of Discovery. This expansive structure reflects the ambitions and spirit of the Portuguese explorers during the Age of Discovery.",
        smallDescription: "Celebrates the Portuguese Age of Discovery.",
        date: "1960",
        century: "Século 20",
        architectureStyle: "Moderno",
        coordinates: [-9.2155, 38.6951],
        region: "Lisboa",
        district: "Lisboa",
        municip: "Lisboa",
        local: "Belém",
        open: "09:00",
        close: "17:00",
        museum: false,
        monument: true,
        nationalMonument: true,
        municipalMonument: false,
        n2: false,
        linkToGoogleStreet: "https://maps.google.com/examplelink",
        ticketPrice: "10 €",
        site: "https://padraodosdescobrimentos.pt/padrao-dos-descobrimentos/",
        additionalInfo: {
            accessibility: "Wheelchair accessible"
        },
        images: [
            "https://www.cm-coimbra.pt/wp-content/uploads/2018/11/dreamstime_73000122_1276x616_acf_cropped-1.jpg",
            "https://www.cm-coimbra.pt/wp-content/uploads/2018/11/dreamstime_73000122_1276x616_acf_cropped-1.jpg"
        ]
    },
    {
        id: "belem-tower",
        name: "Torre de Belém",
        description: "Historic tower guarding the entrance to Lisbon's harbor. Constructed in the early 16th century as part of a defense system at the mouth of the Tagus river, this tower is a prominent example of the Portuguese Manueline style.",
        smallDescription: "Historic defensive tower at Lisbon's harbor.",
        date: "1519",
        century: "Século 16",
        architectureStyle: "Manuelino",
        coordinates: [-9.2159, 38.6916],
        region: "Lisboa",
        district: "Lisboa",
        municip: "Lisboa",
        local: "Belém",
        open: "10:00",
        close: "18:00",
        museum: true,
        monument: false,
        nationalMonument: true,
        municipalMonument: false,
        n2: false,
        linkToGoogleStreet: "https://maps.google.com/examplelink",
        ticketPrice: "6 €",
        site: "https://torrebelem.com/pt/" ,
        additionalInfo: {
            bestVisitTime: "Early morning or late afternoon to avoid crowds"
        },
        images: [
            "https://www.cm-coimbra.pt/wp-content/uploads/2018/11/dreamstime_73000122_1276x616_acf_cropped-1.jpg",
            "https://www.cm-coimbra.pt/wp-content/uploads/2018/11/dreamstime_73000122_1276x616_acf_cropped-1.jpg",
            "https://www.cm-coimbra.pt/wp-content/uploads/2018/11/dreamstime_73000122_1276x616_acf_cropped-1.jpg"
        ]
    },
    {
        id: "sao-jorge-castle",
        name: "Castelo de São Jorge",
        description: "Moorish castle occupying a commanding hilltop overlooking the historic center of Lisbon. The castle is one of the main historical and touristic sites of Lisbon.",
        smallDescription: "Moorish castle on a hilltop with panoramic views of Lisbon.",
        date: "Século 11",
        century: "Século 11",
        architectureStyle: "Mouro",
        coordinates: [-9.1335, 38.7139],
        region: "Lisboa",
        district: "Lisboa",
        municip: "Lisboa",
        local: "São Jorge",
        open: "09:00",
        close: "21:00",
        museum: false,
        monument: true,
        nationalMonument: true,
        municipalMonument: false,
        n2: false,
        linkToGoogleStreet: "https://maps.google.com/examplelink",
        ticketPrice: "8.50 €",
        site: "https://castelodesaojorge.pt/",
        additionalInfo: {
            notableEvents: "Summer concerts and historical reenactments"
        },
        images: [
            "https://www.cm-coimbra.pt/wp-content/uploads/2018/11/dreamstime_73000122_1276x616_acf_cropped-1.jpg",
            "https://www.cm-coimbra.pt/wp-content/uploads/2018/11/dreamstime_73000122_1276x616_acf_cropped-1.jpg",
            "https://www.cm-coimbra.pt/wp-content/uploads/2018/11/dreamstime_73000122_1276x616_acf_cropped-1.jpg"
        ]
    },
    {
        id: "biblioteca-joanina",
        name: "Biblioteca Joanina",
        description: "Historic baroque library within the ancient University of Coimbra, known for its ornate architecture and rare book collections.",
        smallDescription: "Ornate baroque library in the University of Coimbra.",
        date: "1728",
        century: "Século 18",
        architectureStyle: "Barroco",
        coordinates: [-8.4280, 40.2078],
        region: "Coimbra",
        district: "Coimbra",
        municip: "Coimbra",
        local: "Universidade de Coimbra",
        open: "09:00",
        close: "17:00",
        museum: true,
        monument:true,
        nationalMonument: true,
        municipalMonument: false,
        n2: true,
        linkToGoogleStreet: "https://maps.google.com/examplelink",
        ticketPrice: "5 €",
        site: "https://visit.uc.pt/pt/",
        additionalInfo: {
            accessibility: "Limited access",
            notableEvents: "Guided tours available"
        },
        images: [
            "https://www.cm-coimbra.pt/wp-content/uploads/2018/11/dreamstime_73000122_1276x616_acf_cropped-1.jpg",
            "https://www.cm-coimbra.pt/wp-content/uploads/2018/11/dreamstime_73000122_1276x616_acf_cropped-1.jpg",
            "https://www.cm-coimbra.pt/wp-content/uploads/2018/11/dreamstime_73000122_1276x616_acf_cropped-1.jpg"
        ]
    }
];
